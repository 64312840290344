import blogImage from "../images/stock.png"

const  mogData = [
  {
    img:blogImage,
    date:"01-27-2024",
    author:"Abdul mboob",
    title:"Navigating Trading Strategies with Statistical Precision",
    path:"/blog"


}
]
export default mogData;